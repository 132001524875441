import React from 'react';

import { images } from '../../constants';
import './AboutUs.css';

const AboutUs = () => (
  <div className="app__aboutus app__bg flex__center section__padding" id="about">
    <div className="app__aboutus-overlay flex__center">
      <img src={images.G} alt="G_overlay" />
    </div>

    <div className="app__aboutus-content flex__center">
      <div className="app__aboutus-content_about">
        <h1 className="headtext__cormorant">About Us</h1>
        <img src={images.spoon} alt="about_spoon" className="spoon__img" />
        <p className="p__opensans">Discover the rich and vibrant tastes of the Middle East at Dubai Flavors Restaurant, your premier destination for authentic Syrian cuisine Mediterranean food in the heart of the Tagaytay city. We take pride in offering a diverse menu that features traditional dishes made from the freshest ingredients, ensuring every bite is a culinary delight. From savory kebabs and shawarmas to delectable falafels and hummus, our dishes are crafted to bring the flavors of Syria straight to your table. Join us for a dining experience that captures the essence of Middle Eastern hospitality and culinary tradition.</p>
      </div>

      <div className="app__aboutus-content_knife flex__center">
        <img src={images.knife} alt="about_knife" />
      </div>

      <div className="app__aboutus-content_history">
        <h1 className="headtext__cormorant">Our History</h1>
        <img src={images.spoon} alt="about_spoon" className="spoon__img" />
        <p className="p__opensans">At Dubai Flavors Restaurant, we bring you the rich culinary heritage of the Middle East, with a special focus on Syrian cuisine. Established with a passion for authentic flavors and traditional cooking methods, our restaurant has become a beloved spot for food enthusiasts. Our journey began with a desire to share the time-honored recipes passed down through generations. Today, we continue to uphold this legacy by using the finest ingredients and spices, creating dishes that are both nostalgic and innovative. Join us to experience the warmth of Middle Eastern hospitality and a taste of history.</p>
      </div>
    </div>
  </div>
);

export default AboutUs;