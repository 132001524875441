import React from 'react';
import { FiFacebook} from 'react-icons/fi';

import { FooterOverlay, Newsletter } from '../../components';
import { images } from '../../constants';
import './Footer.css';

const Footer = () => (
  <div className="app__footer section__padding" id="login">
    <FooterOverlay />
    {/*<Newsletter />*/}

    <div className="app__footer-links">
      <div className="app__footer-links_contact">
        <h1 className="app__footer-headtext">Contact Us</h1>
        <p className="p__opensans">Magallanes Square Complex, Magallanes Dr, Tagaytay, 4120 Metro Manila 
Philippines</p>
        <p className="p__opensans">+63 927 004 4440</p>
      </div>

      <div className="app__footer-links_logo">
        <img src={images.logo} alt="footer_logo" />
        <p className="p__opensans">&quot;Discover the rich and vibrant tastes of the Middle East at Dubai Flavors Restaurant, your premier destination for authentic Syrian cuisine Mediterranean food in the heart of the Tagaytay city. We take pride in offering a diverse menu that features traditional dishes made from the freshest ingredients, ensuring every bite is a culinary delight. From savory kebabs and shawarmas to delectable falafels and hummus, our dishes are crafted to bring the flavors of Syria straight to your table. Join us for a dining experience that captures the essence of Middle Eastern hospitality and culinary tradition.&quot;</p>
        <img src={images.spoon} className="spoon__img" style={{ marginTop: 15 }} alt='spoon' />
        {/*<div className="app__footer-links_icons">
          <FiFacebook />
        </div>*/}
      </div>

      <div className="app__footer-links_work">
        <h1 className="app__footer-headtext">Working Hours</h1>
        <p className="p__opensans">monday to sunday:</p>
        <p className="p__opensans">10:00 am - 1:30 am</p>
        {/*<p className="p__opensans">Monday:</p>
        <p className="p__opensans">09:00 am - 1:30 am</p>
        <p className="p__opensans">Tuesday:</p>
        <p className="p__opensans">09:00 am - 1:30 am</p>
        <p className="p__opensans">Wednesday:</p>
        <p className="p__opensans">09:00 am - 1:30 am</p>
        <p className="p__opensans">Thursday:</p>
        <p className="p__opensans">09:00 am - 1:30 am</p>
        <p className="p__opensans">Friday:</p>
        <p className="p__opensans">09:00 am - 1:30 am</p>
        <p className="p__opensans">Saturday:</p>
        <p className="p__opensans">09:00 am - 1:30 am</p>*/}
      </div>
    </div>

    <div className="footer__copyright">
      <p className="p__opensans"><a href="https://www.facebook.com/SoftwareSolutionManila/">2021 SoftSolution. All Rights reserved.</a></p>
    </div>

  </div>
);

export default Footer;
