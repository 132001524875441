import images from './images';

const wines = [
  {
    title: 'Homos',
    price: '180₱',
    tags: 'With Beef 280₱ | With Chicken 280₱',
  },
  {
    title: 'Motabal',
    price: '190₱',
  },
  {
    title: 'Falafel',
    price: '290₱',
    tags: '8Pcs | with 2 pita bread',
  },
  {
    title: 'Lebneh',
    price: '275₱',
    tags: 'with 2 pita bread',
  },
  {
    title: 'Makdous',
    price: '250₱',
    tags: '4Pcs | with 1 pita bread',
  },
];

const cocktails = [
  {
    title: 'Shawarma sandwich',
    price: '180₱',
    tags: 'Chicken / Beef | 250₱ Buy 1 Take 1',
  },
  {
    title: "Biryani Rice",
    price: '550₱',
    tags: 'Beef 550₱ | Chicken 450₱ | Solo Chicken 220₱',
  },
  {
    title: 'Shawarma W/ Rice',
    price: '200₱',
    tags: 'Beef | Chicken',
  },
  {
    title: 'Falafel Sandwich',
    price: '150₱',
  },
];

const filipinofood = [
  {
    title: 'Lipton Tea',
    price: '150₱',
  },
  {
    title: "Tea with Cardamon",
    price: '150₱',
  },
  {
    title: 'Black Coffee',
    price: '150₱'
  },
  {
    title: 'Turkish Coffee',
    price: '150₱',
  },
  {
    title: 'Hibiscus Tea',
    price: '150₱',
  },
  {
    title: 'Vimto Drink',
    price: '150₱',
  },
];

const awards = [
  {
    imgUrl: images.award02,
    title: 'Bib Gourmond',
    subtitle: 'Honored for our commitment to delivering exceptional value and flavor.',
  },
  {
    imgUrl: images.award01,
    title: 'Rising Star',
    subtitle: 'Recognized for our innovation and passion in culinary excellence.',
  },
  {
    imgUrl: images.award05,
    title: 'AA Hospitality',
    subtitle: 'Awarded for our unwavering dedication to outstanding service and ambiance.',
  },
  {
    imgUrl: images.award03,
    title: 'Outstanding Chef',
    subtitle: 'Celebrated for our chef s artistry and mastery in the kitchen.',
  },
];

export default { wines, cocktails, filipinofood, awards };
